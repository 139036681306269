.popup {
  position: absolute;
  top: -30px;
  left: 0;
  width: 400px;
  padding: 95px 40px 40px 40px;
  background-color: var(--color_white);
  border-radius: 6px;
  box-shadow: var(--shadow_menu);
  z-index: 19;
  opacity: 0;
  visibility: hidden;
  transition: visibility .2s, opacity .2s;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.textViews {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: solid 1px var(--color_lightGray);
}

.tabs {
  border-radius: 5px;
  border: solid 1px var(--color_primary);
  width: fit-content;
  overflow: hidden;

  & > .tabItem {
    padding: 2px 10px 0;
    line-height: 1.3;
    border: none;
    color: var(--color_primary);
    background: none;
    cursor: pointer;
    transition: color 0.3s ease, background 0.3s ease;
    font-size: var(--fontSize_standart);

    &.active {
      color: var(--color_white);
      background: var(--color_primary);
    }

    &:not(:last-child) {
      border-right: 1px solid var(--color_primary);
    }
  }
}

.title {
  margin: 0;
  width: 140px;
  font-size: var(--fontSize_tab);
  font-weight: bold;
  line-height: normal;
  color: var(--color_gray);
}

.button {
  margin: 0;
  padding: 0;
  font-size: var(--fontSize_standart);
  font-weight: normal;
  align-items: center;
  line-height: 1.2;
  color: var(--color_primary);
  background: none;
  border: none;
  text-align: left;
  display: flex;
  gap: 10px;
  cursor: pointer;

  &.active {
    color: var(--color_darkGray);
    font-weight: bold;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
}

.columnsContainer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.fixes, .comments {
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .title {
    margin-bottom: 10px;
  }
}

.box {
  padding: 2px 10px;
  border-radius: 5px;
  background-color: var(--color_lightGray);
  font-size: var(--fontSize_standart);
  line-height: normal;
  color: var(--color_black);

  &.internal {
    background-color: var(--color_lightGray);
  }
  &.external {
    background-color: #e7f1ff;
  }
}

.counter {
  background-color: var(--color_lightRed);
  font-size: var(--fontSize_tip);
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-weight: bold;
  color: var(--color_white);
  border-radius: 50%;
  transition: opacity .2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 10px;

  & p {
    margin: 0;
    color: var(--color_darkGray);
  }

  & > .label {
    display: flex;
  }

  &.disabled {
    opacity: 0.3
  }
}

.textContainer {
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  & > .checkboxContainer {
    margin-top: 0;
  }
  
  & > .title {
    margin-bottom: 10px;
  }
}