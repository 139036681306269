* {
  box-sizing: border-box;
  outline: none;
  font-family: var(--fontFamily) !important;
}

html {font-size: 10px;}
@media screen and (min-width: 1660px) {
    html {font-size: 13px;}
}
@media screen and (min-width: 2160px) {
    html {font-size: 17px;}
}
@media
only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 2080px),
only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 2080px),
only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 2080px),
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 2080px),
only screen and (                min-resolution: 192dpi) and (min-width: 2080px),
only screen and (                min-resolution: 2dppx)  and (min-width: 2080px) {
    html {font-size: 13px;}
}

body {font-size: 16px;}

.rich-menu {
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  transition: opacity .75s;
  border-radius: 6px;
  background-color: var(--color_primary);
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
  display: flex;
}

.rich-menu .button {
  cursor: pointer;
  padding: 7px 8px 6px 8px;
  color: #77B5FF;
  font-family: Tahoma, serif;
  font-size: var(--fontSize_tab);
  line-height: 17px;
  display: block;
}

.rich-menu .button:not(:first-child) {
  border-left: 1px solid #77B5FF;
}

.rich-menu .button:hover {
  color: #ffffff;
}

.rich-menu .custom-icons {

}

.rich-menu .button[data-active*="true"] {
  color: #FFFFFF;
  background-color: #1962B8;
}

.rich-menu .button:first-child {
  border-radius: 6px 0 0 6px;
}

.rich-menu .button:last-child {
  border-radius: 0 6px 6px 0 ;
}


.custom-scroll:hover {
  background-color: #bababa !important;
}

.private {
  display: none;
}

.remove-mark {
  text-decoration: line-through;
  color: red;
}

.public-remove {
  text-decoration: line-through;
  color: red;
}

.private-remove {
  text-decoration: none;
  color: #212121;
  .remove-mark {
    text-decoration: none;
    color: #212121;
  }
}

.remove-mark-deleted {
  text-decoration: line-through;
  color: #212121;
}


.add-remove {
  display: none;
}

.h1 {
  font-size: var(--fontSize_h1);
  font-weight: bold;
  line-height: 1.18;
  letter-spacing: 0.01px;
  color: #212121;
}
.h2 {
  font-size: var(--fontSize_h2);
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.01px;
  color: var(--color_black);
}
.h3 {
  font-size: var(--fontSize_h3);
  font-weight: bold;
  color: var(--color_black);
}
.h4 {
  font-size: var(--fontSize_h4);
  color: var(--color_gray);
  font-weight: bold;
}
.h4black {
  font-size: var(--fontSize_h4);
  color: var(--color_black);
  font-weight: bold;
}
.space5 {
  height: 5px;
}
.space20 {
  height: 20px;
}
.space40 {
  height: 40px;
}
.space50 {
  height: 50px;
}
.space120 {
  height: 120px;
}
.marginb10 {
  margin-bottom: 10px;
}
.marginb20 {
  margin-bottom: 20px;
}
.marginb40 {
  margin-bottom: 40px;
}
.flex {
  display: flex;
}
.greyback {
  background-color: #f8f8f8;
}
.counter {
  margin-right: 11px;
  font-size: var(--fontSize_tip);
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: var(--color_darkRed);
}

.close-cross { /* HTML template: <span className='close-cross'>+</span> */
  position: absolute;
  top: 30px;
  right: 38px;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #d8d8d8;
  transition: color .2s;
  transform: rotate(-45deg);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    color: #b9b9b9;
  }
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

.at {
  position: relative;
  font-weight: bold;
}

.scaffold {
  height: 30px;
  width: 1px;
  position: relative;
  &::after {
    position: absolute;
    background-color: green;
    width: 2000px;
    height: 1px;
    bottom: 0px;
    left: -600px;
    z-index: 16;
  }
}

.arrow {
  display: inline;
  font-size: var(--fontSize_standart);
  position: relative;
  padding: 0 7px;
  margin: 0;
  &::before,&::after {
    content: '';
    border-radius: 1px;
    position: absolute;
    background-color: var(--color_gray);
    bottom: 6px;
    left: 3px;
    width: 1px;
    height: 8px;
    transform: rotate(-50deg);
    }
  &::after {
    left: 9px;
    transform: rotate(50deg);
  }
  &.arrowOpen::before {
    transform: rotate(50deg);  
  }
  &.arrowOpen::after {
    transform: rotate(-50deg);  
  }
}

.borderHeader {
    height: 50px;
    align-self: flex-start;
    border-left: 1px solid var(--color_lightGray);
    position: relative;
    top: 2px;
}

/* color classes */
.color_primary {
    color: var(--color_primary);
}
.color_orange {
    color: var(--color_orange);
}
.color_lightRed {
    color: var(--color_lightRed);
}

.color_lightGray2 {
  color: var(--color_lightGray2);
}

.color_darkGray {
  color: var(--color_darkGray);
}
/*  */

.helpBtn {
    position: relative;
    height: 40px;
    width: 40px;
    padding: 5px;
    top: 5px;
    right: -5px;
    color: var(--color_primary);
    text-align: center;
    font-size: var(--fontSize_standart);
    font-weight: bold;
    line-height: 35px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: var(--shadow_alert);
    cursor: pointer;
    opacity: 0.5;
    transition: opacity .2s;
    &.helpBtnBlue {
        opacity: 1;
        background-color: var(--color_primary);
        color: white;
    }
    &:hover {
        opacity: 1;
    }
}

.wrapper {
  max-width: inherit;   
}

[data-strokeplus="1"] path {
    stroke: var(--color_black);
}

.pointable {
  cursor: pointer;
}

/* .dragOver {
  display: block;
  width: 360px;
  height: 1px;
  background: var(--color_orange);
  margin-bottom: 10px;
} */

.dragOverTop {
  display: block;
  width: 360px;
  height: 0px;
  /* background: var(--color_orange); */
  padding-bottom: 10px;

  box-sizing: border-box;
  /* background: undefined;
  background: var(--color_orange); */
  border-top: 1px solid var(--color_orange);
}

.dragOverBottom {
  display: block;
  width: 360px;
  height: 0px;
  /* background: var(--color_orange); */
  /* padding-top: 10px; */
  margin-bottom: 10px;

  box-sizing: border-box;
  /* background: undefined;
  background: var(--color_orange); */
  border-bottom: 1px solid var(--color_orange);
}

.cardWrapperZ {
  z-index: 1;
  background: #00000000;
  height: 100%;
  position: absolute;
  width: 100%;
  display: none;
}

.tip > div {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s, visibility .2s;
  bottom: calc(100% + 10px);
  left: 0;
}

.tip:hover > div {
  opacity: 1;
  visibility: visible;
  transition: opacity .2s, visibility .2s;
}  

textarea.disabledColor {
  color: #dedede;
}

.contextMenu {
  position: absolute;
  width: 200px; 
  /* height: 100px; */
  background: #fff;
  box-shadow: var(--shadow_menu);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100000000;

  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.contextMenu {
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  & > .contextBlock {
    padding: 20px;
    border-bottom: 1px solid var(--color_lightGray);
    display: flex;
    flex-direction: column;
    gap: 10px;

    &:last-child {
      border: none
    }

    & > button {
      width: fit-content;
      font-size: 12px;
      color: var(--color_primary);
      border: none;
      background: none;
      outline: none;
      line-height: 1.25;
      padding: 0;
      text-align: left;
      transition: color 0.3s ease;
      display: flex;
      gap: 8px;
      align-items: baseline;
  
      &:hover {
        color: var(--color_primary_hover);
      }
  
      &.green {
        color: var(--color_green);
        display: flex;
        gap: 9px;
        align-items: center;
        
        &:hover {
          color: var(--color_green_hover);
        }
      }
  
      & > img {
        width: 10px;
        margin: 0;
        position: relative;
        top: 2px;
      }
    }
  }
  
}