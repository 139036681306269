.container {
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text {
  margin: 0;
  font-size: var(--fontSize_medium);
  line-height: normal;
  color: var(--color_black);
}

.variableContainer {
  display: flex;
  align-items: baseline;
  gap: 30px;
}

.variableTitle {
  font-size: var(--fontSize_medium);
  line-height: normal;
  color: var(--color_black);
  margin: 0;
  word-wrap: break-word;
  overflow: hidden;
}
.variableName {
  color: var(--color_gray);
}

.icon {
  width: 10px;
  height: 12px;
  padding: 1px 2.5px 0;
  border: solid 1px var(--color_gray);
  font-size: 9px;
  color: var(--color_gray);
}