.container {
  padding: 0 20px 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.button {
  padding: 0;
  border: none;
  background: none;
  outline: none;
  font-size: var(--fontSize_standart);
  line-height: normal;
  color: var(--color_primary);
  width: fit-content;
  cursor: pointer;
}

.label {
  margin: 0;
  font-size: var(--fontSize_tip);
  color: var(--color_gray);
}

.textarea {
  font-size: var(--fontSize_h4);
  border: 1px solid var(--color_lightGray2);
  border-radius: 4px;
  padding: 4px;
  width: 100%;
}

.nameContainer {
  margin: 20px 0;
}

.nameVariable {
  font-size: var(--fontSize_h4);
  width: 100%;
}

.advancedTools {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  color: var(--color_primary);
  font-size: var(--fontSize_standart);
  line-height: normal;

  & .toolsItem {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 0;
    border: none;
    background: none;
    outline: none;
    padding: 0;
    color: var(--color_primary);

    & img {
      max-height: 10px;
      max-width: 10px;
    }
  }

  & .toolItemRemove {
    color: var(--color_darkRed);
  }
}

.readonly {
  cursor: default;

  & > div {
    cursor: default;
  }
}