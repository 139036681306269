.popupBody {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--color_black);

  /* & > * {
    margin: 0;
  } */

  & > h4 {
    margin: 0;
    font-size: var(--fontSize_h3);
    font-weight: 600;
  }

  & > p {
    font-size: var(--fontSize_tab);
    margin: 0;
  }
}

.popupFooter {
  padding: 20px;
  border-top: solid 1px var(--color_lightGray);
  display: flex;
  gap: 10px;
}

.popupFooter__button {
  padding: 2px 10px;
  border-radius: 5px;
  border: solid 1px var(--color_black);
  color: var(--color_black);
  background: none;
  transition: all 0.3s ease;

  & > img {
    margin-right: 10px;
  }

  &.blue {
    border-color: var(--color_primary);
    color: var(--color_primary);

    &:hover {
      border-color: var(--color_primary_hover);
      color: var(--color_primary_hover);
    }
  }
  &.red {
    border-color: var(--color_darkRed);
    color: var(--color_darkRed);

    &:hover {
      border-color: var(--color_darkRed_hover);
      color: var(--color_darkRed_hover);
    }
  }
}