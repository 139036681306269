.container {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: solid 1px var(--color_lightGray);
  width: 100%;
}

.button {
  border: 1px solid var(--color_primary);
  padding: 2px 10px 0;
  color: var(--color_primary);
  outline: none;
  background: none;
  border-radius: 5px;
  line-height: 1.3;
  font-size: var(--fontSize_button);
  transition: all 0.2s ease;
  
  &:hover {
    color: var(--color_primary_hover);
    border: 1px solid var(--color_primary_hover);
  }
  
  &.red {
    color: var(--color_darkRed);
    border: 1px solid var(--color_darkRed);

    &:hover {
      color: var(--color_darkRed_hover);
      border: 1px solid var(--color_darkRed_hover);
    }
  }
}