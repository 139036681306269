.checkbox {
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  background: transparent;
  border: 1px solid #ddd;
  border-radius: 2px;
  justify-content: center;
  cursor: pointer;
}

.checkbox > svg {
  margin-right: 0;
  width: 75%;
}

.checkbox.active.blue {
  border-color: var(--color_primary);
  background-color: var(--color_primary);
}

.checkbox.active.green {
  border-color: var(--color_green);
  background-color: var(--color_green);
}

.label {
  display: flex;
  gap: 8px;
  align-items: center;

  & > p {
    margin: 0;
    line-height: normal;
    color: #606060;
    font-size: 12px;
  }
}
