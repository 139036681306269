.wrapper {
    margin-left: 20px;
    user-select: none;
    position: relative;
}
.screen {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 12;
    background-color: white;
}
.row {
    display: flex;
    align-items: baseline;
    color: var(--color_darkGray);
    font-size: var(--fontSize_standart);
    &.centered {
        align-items: center;
    }
}

.bordered {
    padding: 2px 10px 2px;
    border-radius: 5px;
    border: solid 1px var(--color_lightGray2);
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    height: 100%;
    &.opened {
        border-radius: 5px 5px 0 0;
    }
}
.fontPicker {
    flex-basis: 148px;    
    position:relative;
    height: 19px;
}
.shifted5 {
    top: 7px;
}
.pickColor {
    display: inline-block;
    height: 13px;
    width: 4px;
    border-radius: 2px;    
    margin: 0 4px;
}
.back-picker {
    position: relative;
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 16px;
}

.color-picker {
    display: flex;
    align-items: center;
    margin-left: 5px;
    &.table {
        margin: 0px 20px;
    }
}

.arrow-container {
    height: 7px;
}

.zindex3 {
    z-index: 3;
}

.sizePicker {
    flex-basis: 67px;
    margin-left: 10px;
    margin-right: 20px;
    padding: 2px 5px;
    height: 19px;
}

.BIH {
    display: flex;
    align-items: center;
}
.sign {
    color: var(--color_primary);
    font-weight: bold;
    font-size: 16px;
    font-family: sans-serif !important;
    line-height: 1;
    &.selected {
        color: var(--color_black);
    }    
    &.bold {
      min-width: 20px;
      margin-right: 1px;
    }
    &.italic {
       font-style: italic;
       font-weight: normal;
       font-family: serif !important;
       min-width: 15px;
       margin-right: 5px;
    }
    &.minus {
        position: relative;
        font-family: monospace !important;
        font-weight: normal;
        font-size: var(--fontSize_standart);
    }
    &.color {
        font-weight: normal;
        margin-right: 10px;
    }
    &.bgColor {
        padding: 1px;
        margin-right: 10px;
        font-weight: normal;
        font-size: var(--fontSize_tip);
        width: 13px;
        height: 13px;
        text-align: center;
        border: 1px dotted var(--color_primary);
    }
}
.clickable {
    cursor: pointer;
}
.svgIcons {
  width: 128px;
  height: 19px;
  margin-right: 20px;
  border-radius: 5px;
  border: solid 1px var(--color_lightGray2);
  display: flex;  
  & > div {
      text-align: center;
      flex-grow: 1;
  }
  & > :not(:last-child) {
      border-right: solid 1px var(--color_lightGray2);
  }
}
.prima {
    color: var(--color_primary);
}

.marg12 {
    margin-top: 12px;
}

.marg10 {
    margin-top: 10px;
}

.marg20 {
    margin-top: 20px;
}

.marg25 {
    margin-top: 25px;
}

.margl20 {
    margin-left: 20px;
}

.font_popup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0 10px 5px;
    background-color: #fff;
    transition: all .2s;
    border-radius: 0 0 5px 5px;
    z-index: 2;
    height: 200px;
    border: solid 1px var(--color_lightGray2);
    border-top: none;
}
.font-line {
    padding: 3px 0;
    &:hover {
        color: var(--color_primary);
    }
}

.highlights {
    position: absolute;
    padding: 60px 20px 20px;
    z-index: 2;
    width: 300px;
    height: 118px;
    box-shadow: var(--shadow_menu);
    background-color: #fff;
    top: -20px;
    right: -20px;
    border-radius: 6px;
    font-size: var(--fontSize_frameNormal);
}
.noHighlight {
    color: var(--color_primary);
    line-height: 18px;
    width: 60px;
    height: 40px;
    border-radius: 2px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dotted var(--color_primary);
    &.active {
        border: 1px solid var(--color_primary);
    }
}

.highlightBorder {
    width: 60px;
    height: 40px;
    margin-right: 2px;
    &.active {
        border: 1px solid var(--color_primary);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
    }
}

.mainHighlight {
    width: 60px;
    height: 40px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
        color: var(--color_white);
        font-size: var(--fontSize_standart);
    }

    &.active {
        border-radius: 0px;
        width: 56px;
        height: 36px;
    }
}
.smallHighlights {
    width: 198px;
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    align-content: space-between;
    & > div {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        cursor: pointer;

        &.white > div {
            border: 1px solid var(--color_lightGray);
        }

        & > div {
            width: 18px;
            height: 18px;
            border-radius: 2px;
        }

        &.active {
            padding: 2px;
            border: 1px solid var(--color_primary);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.active > div {
            width: 12px;
            height: 12px;
            border-radius: 0px;
        }
        
    }
}
.vertical {
    width: 1px;
    background-color: var(--color_lightGray);
    align-self: stretch;
}
.copyBtn {
    width: 14px;
    height: 14px;
    background-image: url('/images/icon-link.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.ptClass {
    color: var(--color_lightGray2);
}

.headingContainer {
    display: flex;
    flex-direction: column;
}

.flexRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.heading {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    margin-bottom: 2px;
}

.changeIndent {
    flex-basis: 67px;
    width: 67px;
    margin-right: 15px;
    padding: 2px 5px;
}

.stylesBtns {
    margin-left: 20px;
    display: flex;
    & :first-child {
        border-radius: 5px 0 0 5px;
        border-right: 0px;
    }
    & :last-child {
        border-radius: 0 5px 5px 0;
        border-left: 0px;
    }
}

.stylesBtn {
    padding: 2px 10px;
    border: solid 1px var(--color_lightGray2);
    &.active {
        border: solid 1px var(--color_gray);
        background-color: var(--color_gray);
        color: var(--color_white);
    }
    &:hover {
        cursor: pointer;
    }
}

.stylesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 10px;
}

.blurOptions {
    opacity: 0.25;
    cursor: default;
    user-select: none;
    pointer-events: none;
}

.numberingButtons {
    display: flex;
}

.tempBtn {
    position: relative;
    cursor: pointer;
    background-color: var(--white);
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: 13px;
    height: 13px;
    & > img {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

.toggles {
    background-color: white;
    border-bottom: 1px solid var(--color_lightGray);
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    display: inline-block;
    height: 24px;
    margin: 30px 0px 10px;
    z-index: 2;
    .toggle {
        position: relative;
        display: inline-block;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all .2s;
        user-select: none;
        &:not(:last-child) {
            margin-right: 20px;
        }
        &:hover {
            color: var(--color_primary_hover);
        }
        &._new {
            color: var(--color_primary);
            font-weight: bold;
        }
        &._unsel {
          color: var(--color_lightGray);
          cursor: initial;
          &:hover {
            color: var(--color_lightGray);
          }
        }
        &._sel {
            color: var(--color_darkGray);
            &::after {
                display: block;
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -10px;
                left: 0;
                border-radius: 2px 2px 0 0;
                background-color: var(--color_lightGray2);
            }
        }
    }
  }

.tables {
    position: absolute;
    padding: 60px 20px 20px;
    z-index: 2;
    width: 238px;
    height: 283px;
    box-shadow: var(--shadow_menu);
    background-color: #fff;
    top: -20px;
    right: -20px;
    border-radius: 6px;
}

.cell {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 1px solid var(--color_lightGray2);
    margin-right: 2px;
    &.active {
        border-color: var(--color_primary);
    }
}

.row {
    margin-bottom: 2px;
}

.tableSize {
    position: absolute;
    top: 40px;
    left: 182px;
    font-size: var(--fontSize_standart);
    margin: 0px;
}