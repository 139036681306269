.container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.option {
  font-size: var(--fontSize_readable);
  line-height: normal;
  color: var(--color_darkGray);
  
  &.isDefault {
    font-weight: bold;
  }

  &:placeholder-shown::placeholder {
    color: var(--color_gray);
  }
}

.hint {
  font-size: var(fontSize_standart);
  line-height: normal;
  color: var(--color_darkGray);

  &:placeholder-shown::placeholder {
    color: var(--color_primary);
  }
}

.checkbox {
  & > p {
    width: max-content;
  }
}

.readonly {
  cursor: default;

  & > div {
    cursor: default;
  }
}