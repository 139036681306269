.body {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.header {
  position: relative;
  display: inline-flex;
  gap: 12px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: -webkit-fill-available;
}

.title {
  font-size: var(--fontSize_h4);
  font-weight: bold;
}

.leftLine {
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  left: -20.5px;
  background: var(--color_orange);
}

.hint {
  font-size: var(--fontSize_standart);

  &:placeholder-shown::placeholder {
    color: var(--color_primary);
  }
}

.readonly {
  cursor: default;

  & > div {
    cursor: default;
  }
}