.container {
  background: #fff;
  box-shadow: -5px 0 80px 0 #00000026;
  border-radius: 5px;
  font-size: 14px;
  color: #212121;
  width: 300px;
  position: relative;
  max-height: calc(100vh - 40px);

  &.w-670 {
    width: 670px;
    border-radius: 10px;

    & > .cross {
      top: 38px;
      right: 38px;
    }
  }

  &.w-400 {
    width: 400px;
    border-radius: 10px;

    & > .cross {
      top: 38px;
      right: 38px;
    }

    &.scroll {
      overflow-y: auto;
      max-height: calc(100vh - 80px);
      scrollbar-width: none;
      -ms-overflow-style: none;
      
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.cross {
  position: absolute;
  top: 18px;
  right: 18px;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  max-width: 19px;
  max-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > img {
    margin: 0;
    max-width: inherit;
    max-height: inherit;
  }
}

.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000048;

  &.whiteBackground {
    background-color: rgba(255, 255, 255, 0.9);
  }
}