.container {
  display: flex;
  justify-content: space-between;
  padding: 7.5px 20px 10px;
  border-top: var(--dashed_border);
}

.advancedButton {
  padding: 0;
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 10px;
}
