.button {
  background-color: var(--color_white);
  font-size: var(--fontSize_tab);
  padding: 10px 20px;
  border: 1px solid var(--color_primary);
  color: var(--color_primary);
  border-radius: 5px;
  transition: all .2s;
  display: flex;
  gap: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    cursor: default;
  }
}

.container {
  padding: 0 20px 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}