.container {
  padding-top: 7px;
  padding-left: 20px;
  width: 420px;
}

.togglerVisible {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  width: fit-content;
  /* margin-top: 2px; */
}

.togglerVisible_item {
  background: var(--color_white);
  padding: 2px 10px;
  color: var(--color_darkGray);
  border-top: solid 1px var(--color_lightGray2);
  border-bottom: solid 1px var(--color_lightGray2);
  font-size: var(--fontSize_standart);
  line-height: 1.2;
  overflow: hidden;
  cursor: pointer;
  transition: background 0.3s ease;
  
  &:first-child {
    border-left: solid 1px var(--color_lightGray2);
    border-radius: 5px 0 0 5px;
  }
  
  &:last-child {
    border-right: solid 1px var(--color_lightGray2);
    border-radius: 0 5px 5px 0 ;
  }

  &.active {
    background-color: var(--color_gray);
    color: var(--color_white);
    border-color: transparent;

    &:hover {
      background-color: var(--color_gray_hover);
    }
  }
}

.analysesButton {
  color: var(--color_primary);
  display: flex;
  gap: 8px;
  font-size: var(--fontSize_standart);
  line-height: 1.2;
  margin-top: 20px;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  transition: color, opacity 0.3s ease;

  & > p {
    margin: 0;
  }

  & > img {
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: var(--color_primary_hover);
  }
}

.list {
  margin-top: 69px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  margin-bottom: 40px;
}

.listItem {
  border-radius: 8px;
  box-shadow: var(--shadow_alert);
  border: dashed 1px var(--color_yellow);
  width: 400px;
  font-size: var(--fontSize_tab);
  line-height: 19px;
  
  
  &.resolved {
    border: dashed 1px rgba(255, 192, 3, 0.5);
    width: 360px;
  }

  &.active {
    width: 400px;
    box-shadow: none;
  }
}

.itemBody {
  padding: 20px;
}

.itemBody__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.itemBody__headerAnalysis {
  display: flex;
  gap: 4px;
  color: var(--color_gray);
  align-items: center;
  font-size: var(--fontSize_tip);

  & > img {
    width: 6px;
  }
}

.itemBody__content {
  display: flex;
  gap: 15px;
}

.itemBody__leftCol {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color_yellow);

  & > img {
    width: 12px;
    height: 12px;
  }
}

.itemBody__mainCol {
  flex: 1;
}

.itemBody__mainColHeader {
  display: flex;
  gap: 10px;
  font-size: var(--fontSize_standart);
  margin-bottom: 10px;

  & > h4 {
    color: var(--color_darkGray);
    font-weight: bold;
    margin: 0;
  }
  
  & > p {
    color: var(--color_gray);
    margin: 0;
  }
}

.itemBody__mainColDefault {
  display: flex;
  justify-content: space-between;
}

.itemBody__mainColDefaultText {
  border-left: 2px solid var(--color_lightGray2);
  color: var(--color_lightGray2);
  padding-left: 9px;
  margin: 0;
}

.itemBody__mainColNav {
  display: flex;
  align-items: start;
  gap: 20px;
  height: 16px;

  & > .arrows {
    display: flex;
    gap: 14px;

    & > img {
      cursor: pointer;
    }
  }

  & > img {
    cursor: pointer;
  }
}

.itemBody__mainColComment {
  margin: 10px 0 0 0;
}

.itemBody__mainColPolicy {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
  & > p {
    margin: 0;
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    line-height: 1.2;
  }
}

.policyPopover {
  position: absolute;
  width: 200px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: var(--shadow_alert);
  background-color: var(--color_darkGray);
  color: var(--color_lightGray2);
  font-size: var(--fontSize_tip);
  display: flex;
  flex-direction: column;
  gap: 6px;
  bottom: calc(100% + 10px);
  transition: opacity 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 18px;
    width: 14px;
    height: 14px;
    border-radius: 1px;
    background-color: var(--color_darkGray);
    transform: rotate(45deg);
  }

  & > h5 {
    font-weight: bold;
    font-size: var(--fontSize_tip);
    margin: 0;
  }

  & > p {
    margin: 0;
    line-height: 1.5;
  }
}

.itemsFooter {
  display: flex;
  gap: 20px;
  padding: 10px 20px;
  display: flex;
  gap: 20px;
  border-top: dashed 1px var(--color_yellow);
}

.itemFooter__item {
  font-size: var(--fontSize_frameNormal);
  line-height: 1.2;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.green {
    color: var(--color_green);
  }
  
  &.red {
    color: var(--color_darkRed);
  }

  &.gray {
    color: var(--color_darkGray);
  }

  &.blue {
    color: var(--color_primary);
  }

  & > img {
    margin-right: 10px;
  }

  & > .arrow {
    margin-right: 0;
    margin-left: 10px;
  }
}

.advanced {
  padding: 20px;
  border-top: dashed 1px var(--color_yellow);
}

.advanced__title {
  line-height: 1.2;
  font-size: var(--fontSize_tab);
  font-weight: bold;
  color: var(--color_gray);
}

.advanced__content {
  display: flex;
  gap: 6px;
  font-size: var(--fontSize_tab);
  margin-top: 10px;
  color: var(--color_darkGray);

  & > .default {
    text-decoration: line-through;
    text-decoration-color: var(--color_orange);
    text-decoration-style: solid;
  }
  & > .correction {
    text-decoration: underline;
    text-decoration-color: var(--color_orange); 
    text-decoration-style: solid;
  }
}

.advanced__button {
  margin-top: 20px;
  font-size: var(--fontSize_standart);
  color: var(--color_green);
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.hiddenList {
  padding-bottom: 50px;
}

.hiddenList__item {
  font-size: var(--fontSize_standart);
  line-height: 19px;
  color: var(--color_gray);
  display: flex;
  gap: 8px;
  align-items: center;

  & > img {
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}

.analyzeInfo {
  font-size: var(--fontSize_standart);
  line-height: 1.2;
  color: var(--color_gray);
  margin: 3px 0 0 0;
}

.rulesInput {
  width: 100%;
  resize: vertical;
  min-height: 80px;
  margin-top: 12px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 6px;
  font-size: var(--fontSize_standart);
}

.expDocSpinner {

  margin-top: 20px;
  padding: 0;

  font-size: var(--fontSize_standart);
  height: 20px;
  color: var(--color_gray);
  display: inline-flex;
  align-items: center;
  user-select: none;
  > div {
      position: static;
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px !important;
      img {
          height: 15px;
          width: 15px;
          margin: 0;
      }
  }
}