.container {
  width: 360px;
  position: relative;
  height: fit-content;
  border-radius: 8px;
  border: var(--dashed_border);
  background-color: var(--color_white);
  margin-bottom: 10px;
  transition: background-color 0.2s;
}
