.menu {
    position: relative;
    z-index: 21;
}

.wrapper {
  position: relative;
  font-size: var(--fontSize_standart);
  white-space: nowrap;
  margin-right: 39px;
  padding-left: 40px;
  user-select: none;
  &.jump {
      .popup {
          top: -30px;
      }
  }
  &.open {
      & .desc, & .changesJump, & .changesUndo {
          z-index: 6;
          transition-delay: 0s;
      }
  }
}

.changesJump, .changesUndo {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  transform: translateY(7px);
  margin-left: 16px;
  z-index: 2;
  transition-delay: .2s;
  & .undo {
      position: relative;
      display: inline-block;
      width: 24px;
      margin-left: 4px;
      cursor: pointer;
      & img {
          height: 11px;
          top: -5px;
          position: relative;
      }
      &.reverse {
          margin: 0 8px 0 6px;
      }
      &.disabled {
          cursor: default;
      }
      &.reverse img {
          transform: rotateY(180deg);
      }
      &.disabled img {
          opacity: 0.3;
      }
      & > div {
          top: 32px;
          left: -13px;
          visibility: hidden;
          opacity: 0;
      }
      &:hover > div {
          visibility: visible;
          opacity: 1;
          transition: opacity .2s .5s, visibility .2s .5s;
      }
  }
  & .left, & .right {
      position: relative;
      display: inline-block;
      width: 10px;
      height: 20px;
      cursor: pointer;
      & > div {
          top: 32px;
          left: -13px;
          visibility: hidden;
          opacity: 0;
      }
      &:hover > div {
          visibility: visible;
          opacity: 1;
          transition: opacity .2s .5s, visibility .2s .5s;
      }
      &::before, &::after {
          content: '';
          border-radius: 1px;
          background-color: var(--color_primary);
          width: 8px;
          height: 1px;
          transform: rotate(-45deg);
          position: absolute;
          top: calc(50% + 2px);
          right: 0;
          transition: background-color .2s;
      }
      &::after {
          top: 7px;
          transform: rotate(45deg);
      }
      &.disabled {
          cursor: default;
          &::before, &::after {
              background-color: var(--color_lightGray2);
          }
      }
      &:not(.disabled):hover::before, &:not(.disabled):hover::after {
          background-color: var(--color_primary_hover);
      }
  }
  & .left {
      margin-right: 16px;
      &::before {
          transform: rotate(45deg);
      }
      &::after {
          transform: rotate(-45deg);
      }
  }
}

.desc {
  position: relative;
  white-space: nowrap;
  padding-top: 2px;
  font-size: var(--fontSize_tip);
  color: var(--color_gray);
  z-index: 2;
  transition-delay: .2s;
}

/* .screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.desc {
  position: relative;
  white-space: nowrap;
  padding-top: 2px;
  font-size: var(--fontSize_tip);
  color: var(--color_gray);
  z-index: 2;
  transition-delay: .2s;
}

.subTitle {
  color: var(--color_gray);
  margin-bottom: 5px;
  transition: all .2s;
}

.under-popup._vis {
  position:relative;
  z-index:21;
}

.changes {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--color_lightGray);
  &-toggles {
      display: flex;
      flex-wrap: wrap;
      height: 56px;
      align-content: space-between;
      & > button:nth-child(3) {
          margin-right: 0;
      }
  }
  &-btn {
      line-height: 14px !important;
      padding: 0;
      border: none;
      background: transparent;
      font-size: var(--fontSize_standart);
      color: var(--color_primary);
      cursor: pointer;
      text-align: left;
      width: fit-content;
      height: fit-content;
      white-space: nowrap;
      transition: color .2s;
      margin-right: 40px;
      &._selected {
          color: var(--color_gray);
          cursor: default;
      }
      &._disabled {
          color: rgba(21, 126, 251, 0.3);
          cursor: default;
      }
      &._bold {
          font-weight: bold;
      }
      &:hover:not(._selected):not(._disabled) {
          color: var(--color_primary_hover);
      }
  }
  &-onlyNew {
      position: relative;
      width: fit-content;
      max-width: 93px;
      white-space: normal;
      padding-right: 20px;
      margin-right: 40px;
      transform: translateY(-32px);
      > button {
          transition: opacity .2s, border .2s, color .2s, background-color .2s;
      }
      & .counter {
          display: inline-block;
          position: absolute;
          top: -2px;
          right: -12px;
          background-color: var(--color_lightRed);
          font-size: var(--fontSize_tip);
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-weight: bold;
          text-align: center;
          color: var(--color_white);
          border-radius: 50%;
          transition: opacity .2s;
      }
  }
  .changes-2versionDesc {
      font-size: var(--fontSize_tip);
      color: var(--color_gray);
      margin-top: 5px;
      transition: all .2s;
  }
  &._dis &-btn, &._dis {
      color: rgba(21, 126, 251, 0.3);
      cursor: default;
      &:hover {
          transition-delay: 999999999s;
      }
  }
  &._dis {
      .changes-2versionDesc, .subTitle, .changes-onlyNew button, .changes-onlyNew .counter {
          opacity: 0.3;
          cursor: default;
          &:hover {
              transition-delay: 999999999s;
          }
      }
  }
}

.showParagraphs, .comments {
  margin-bottom: 40px;
}

.showText {

}


 */
