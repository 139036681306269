.button {
  font-weight: bold !important;
  color: var(--color_orange) !important;
  user-select: none;
  font-size: 12px;
  line-height: 15px;
  border: none;
  background-color: white;
  width: fit-content;
  padding: 0;
  display: flex;
  align-items: center;
  &[data-off="true"] {
    opacity: 0.3;
    cursor: default;
  }
}

.button img {
  margin-right: 11px;
  aspect-ratio: 11 / 12;
  width: 11px;
}