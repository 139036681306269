.wrapper {
    margin-top: 50px;
    padding-bottom: 300px;
    font-size: var(--fontSize_standart);
    color: var(--color_darkGray);
    min-width: 1146px;
}
.wrapperUser {
    margin-left: 20px;
    padding-bottom: 40px;
    font-size: var(--fontSize_standart);
    width: 400px;
}
.pdOptions {
    line-height: 1.5;
    color: var(--color_black);
    margin: 0;
    padding: 2px 0 1px;
}

.dFind {
    width: 200px;
    margin: 0 20px 0 10px;
    border-radius: 4px;
    border: solid 1px #dedede;
    padding: 0 12px 0 4px; 
    margin: 0 50px 0 20px
}
.nfound {
    color: var(--color_darkRed);
}
.crossPlace {
    right: 16px;
    position: relative;   
    z-index: 2;
    top: -46px;
    &.corrected {
        right: 21px;
        top: -32px;
    }
}

.head {
    display: flex;
    align-items: baseline;
    margin-top: 1px;
    margin-bottom: 1px;
    &_radio {
      display: flex;
      margin-left: 20px;
      margin-right: 20px;
      & + .head_radio {
          margin-left: 0;
      }
      > div {
        line-height: 1.23;
        color: var(--color_darkGray);
        display: inline-block;
        padding: 2px 10px 1px;
        border: 1px solid var(--color_lightGray2);
        transition: all .2s;
        cursor: pointer;
        user-select: none;
        &[data-current="true"] {
          color: #fff;
          background-color: var(--color_gray);
          border-color: var(--color_gray);
        }
        &:hover:not([data-current="true"]) {
          color: var(--color_primary_hover);
        }
      }
      > div:not(:first-child):not(:last-child) {
        border-left: none;
        border-right: none;
      }
      > div:first-child {
        border-radius: 5px 0 0  5px;

      }
      > div:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }

.line {
    border-top: solid 1px var(--color_lightGray);    
    padding-bottom: 19px;
    padding-top: 20px;
    /* margin-bottom: 20px; */
    display: flex;
    &.lhidden {
      border-color: white;
   }
    & img, & svg {
        margin-right: 27px;
    }
    & > * {
        flex-grow: 0;
        flex-shrink: 0;
    }
}
.tab-headers {
    padding-bottom: 10px;
    padding-left: 40px;
    border-bottom: solid 1px var(--color_lightGray2);
    line-height: 1.5;
    color: var(--color_gray);
    margin-top: 20px;
    margin-bottom: 40px;
    position: relative;
    border-top: none;
}
.tab-cat {
    width: 320px;
    display: inline-block;
    max-height: 72px;
    margin-right: 40px;
    overflow-wrap: break-word;
}
.tab-clause {
    width: 390px;
    overflow-wrap: break-word;
    display: inline-block;
    max-height: 72px;
    margin-right: 50px;
    overflow-y: clip;
    white-space: pre-wrap;
}
.tab-status {
    width: 80px;
    display: inline-block;
    max-height: 72px;
    margin-right: 20px;
    &.tab-draft {
        color: var(--color_gray);
    }
    &.tab-last {
        /* margin=right: 0; */
    }
}

.blank {    
    line-height: 1.5;
    color: var(--color_gray);
}

.arrowShift  {
    left: 10px;
    top: 0;
    cursor: pointer;
}

.editFixed {
    position: absolute;
    font-size: var(--fontSize_standart);
    top: 100%;
    z-index: 2;
    width: 800px;
    border-radius: 8px;
    border: 1px dashed var(--color_orange);
    background-color: white;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 / 11%);    
}
.upperSide {
    padding: 20px;
    display:flex;
    gap: 40px;
    & textarea {
      font-family: 'ProximaNova', sans-serif !important;
      width: 100%;
      border: none;
      padding: 0;
      margin: 0;
      resize: none;
      /* font-size: var(--fontSize_readable); */
      &:nth-of-type(2)::placeholder {
        color: var(--color_primary);
      }
    }
}
.upperLeft {
    display: inline-block;
    width: 280px;
    /* margin-right: 60px; */
}
.upperFull {    
    width: 100%;
}
.upperRight {
    display: inline-block;
    width: 398px;
}
.smallTitle, .hint {
    color: var(--color_gray);
    font-size: var(--fontSize_tip);
}
.hinted {
    position: relative;
    &::before {
        position: absolute;
        left: 1px;
        top: 0px;
        content: attr(data-content);
        color: var(--color_primary);
    }
    &.orange::before{
        color: var(--color_orange);
    }
}

.lowerSide {
    border-top: 1px dashed var(--color_orange);
    padding: 8px 20px;
    display: flex;
    gap: 20px;
    & > span {
        cursor: pointer;
        color: var(--color_primary);
        font-size: 12px;
        /* margin-right: 20px; */
        position: relative;
        display: flex;
        align-items: center;
        gap: 9px;

        &.tip > span {
            display: inline-block;
            line-height: 16px;
        }

        & > .image {
            max-width: 10px;
            max-height: 12px;
            margin: 0;
        }
        &.inactive {
            cursor: initial;
            color: var(--color_primary_semi);
            &.orange {
                color: var(--color_orange_semi);
            }
        }
        &.orange {
            color: var(--color_orange);
        }
        &.blue {
            color: var(--color_primary);
            &[data-is-disabled='true'] {
                color: var(--color_primary_semi);
            }
        }
        &.red {
            color: var(--color_darkRed);
        }
        &.green {
            color: var(--color_green);
            &[data-is-disabled='true'] {
                color: var(--color_green_semi);
            }
        }
        &.distant {
            margin-left: 40px;
        }
        &.tip > div {
            opacity: 0;
            visibility: hidden;
            transition: opacity .2s, visibility .2s;
            bottom: 22px;
            left: 0;
        }
        &.tip:hover > div {
            opacity: 1;
            visibility: visible;
            transition: opacity .2s, visibility .2s;
        }        
        &.ml20 {
            margin-left: 20px;
        }
    }
}

.cats {
    position: absolute;
    top: -40px;
    left: 0px;
    color: var(--color_darkGray);
    border-radius: 10px;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
    padding: 40px 40px 20px;
    background-color: white;
    z-index:2;
    width: 400px;

    & > h3 {
        font-weight: 400;
        font-size: var(--fontSize_h4);
    }
}
.show-folder {
    line-height: 18px;
    &:hover {
        color: var(--color_primary_hover);
    }
    &.selected-folder {
        color: var(--color_black);
        font-weight: bold;
    }
}
.draft {
    color: var(--color_gray);
}

.maskLine {
    & > .categoryWrapper:first-child {
        & > .preline > .line {
            border-top: none;
        }
    }
}

.categoryWrapper {
    & > .preline + .categoryWrapper {
        /* background: #000; */
        & > .preline > .line {
            border-top: none;
        }
    }
    /* & > .preline > .line {
        border-top: none;
    } */
}


.card {
    border-radius: 8px;
    box-shadow: var(--shadow_alert);
    padding: 20px;
    margin: 20px 0;
    border: var(--dashed_border);
    position: relative;

    /* &.draft {
        border-color: var(--color_lightGray);
    } */
    &.edited .clause {
        -webkit-line-clamp: 8;
    }
    &:before {
        content: '';
        display: block;
        width: calc(100% + 80px);
        height: 1px;
        background-color: white;
        position: absolute;
        top: -2px;
        left: -40px;
    }
}
/* .preline+.card {
    margin-top: -20px;
} */
.title {
    font-weight:bold;
    color: var(--color_darkGray);
    font-size: var(--fontSize_readable);
}
.sideCardHint {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    margin-top: 5px;
}
.clause {
    font-size: var(--fontSize_readable);
    color: var(--color_darkGray);
    /* display: -webkit-box; */
    /* -webkit-box-orient: vertical; */
    /* -webkit-line-clamp: 9; */
    max-height: 155px;
    overflow: hidden;
    white-space: pre-wrap;
    margin-top: 20px;
    position: relative;
    padding-bottom: 8px;

    &::after {
        content: '';
        position: absolute; /* Абсолютное позиционирование для эффекта размытия */
        bottom: 0; /* Расположение внизу элемента */
        left: 0; 
        right: 0;
        height: 14px; /* Высота размытия */
        background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); /* Градиент от белого к прозрачному */
        filter: blur(3px); /* Эффект размытия */
        pointer-events: none;
    }

    &.active {
        max-height: none;
        &::after {
            filter: blur(0);
        }
    }
}

.sideCardHeader {
    display: flex;
    gap: 10px;
    align-items: baseline;

    & .isDraft {
        position: relative;
        max-height: 10px;
        & > img {
            max-width: 10px;
        }
    }

    & .tagContainer {
        position: relative;
    }

    & .tag {
        width: 10px;
        height: 10px;
        border-radius: 50%;

        &.green {
            background-color: var(--color_green);
        }
        &.yellow {
            background-color: var(--color_yellow);
        }
        &.red {
            background-color: var(--color_darkRed);
        }
    }
}

.subclause {
    width: calc(100% + 40px);
    margin: 10px -20px -20px;
    padding: 10px 20px;
    /* border-top: dashed 1px var(--color_orange_semi); */
    border-top: var(--dashed_border);
    display: flex;
    /* &.draft {
        border-color: var(--color_lightGray);
    } */
    & span {
        color: var(--color_primary);
        cursor: pointer;
        margin-right: 40px;
        line-height: normal;
        display: flex;
        gap: 10px;
        align-items: center;
        &:nth-of-type(1) {
            color: var(--color_orange);
            margin-right: 20px;
        }
        &.lvl {
            margin-right: 20px;
        }
        &[data-off="true"] {
            opacity: 0.3;
            cursor: default;
        }
    }
}
.buttons {
    display: flex;
    align-items: baseline;
    margin-top: 50px;
}
.maskLine:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: transparent;
    /* background-color: white; */
    position: relative;
    top: -21px;
}

[data-x="1"] {
    background-color: var(--color_yellow_semi);
}

.subitem {
    margin-top: 16px;
    color: var(--color_primary);
    display: inline-flex;
    gap: 7px;
    align-items: center;
    transition: color 0.3s ease;
    cursor: pointer;

    & > span, & > img {
        width: 13px;
        margin: 0;
        transition: opacity 0.3s ease;
    } 

    &:hover {
        & > span, & > img {
            /* opacity: 0.3; */
        } 
        color: var(--color_primary_hover);
    }

    &.covenant {
        color: var(--color_darkGray);

        &:hover {
            color: var(--color_darkGray);
        }
    }

    &.m0{
        margin: 0;
    }
}

.mt16 {
    margin-top: 16px;
}

.mt20 {
    margin-top: 20px;
}

.alert {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.mt5 {
    margin-top: 5px;
}
.mt10 {
    margin-top: 10px;
}
.colorRed {
    color: var(--color_lightRed);
}
.bold {
    font-weight: 600;
}
.colorPrimary {
    color: var(--color_primary);
    transition: color 0.3s ease;
    
    &:hover {
        color: var(--color_primary_hover);
    }
}
.cursorPointer {
    cursor: pointer;
}
.inline-block {
    display: inline-block;
}

.colorBlockContainer {
    font-size: 12px;
    color: var(--color_black);
    margin-top: 10px;

    .colorBlock {
        padding: 2px 10px;
        border-radius: 5px;
        font-size: 12px;
        color: var(--color_light_black);
        background: var(--color_green_light);
        display: inline;
    
        &.orangeBg {
            background: var(--color_orange_light);
        }
    }
}

.lh16 {
    line-height: 16px;
}
span.w-auto {
    width: auto;
}

.buttonContainer {
    margin-top: 21px;
    display: flex;
    gap: 10px;

    & > button {
        line-height: normal;

        &:disabled {
            background: var(--color_primary_semi);
            color: #fff;
            border-color: unset;
            
            &:hover {
                background: var(--color_primary_semi);
                color: #fff;
                border-color: unset;
            }
        }
    }
}

.lh-normal {
    line-height: normal;
}
