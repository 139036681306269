.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.button {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  color: var(--color_orange);

  border: none;
  padding: 0;
  background: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.3
  }
}
.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}