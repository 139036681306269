.container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 0 20px 20px;
  align-items: flex-start;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    background: none;
    border: none;
    outline: none;
    padding: 0;
    width: max-content;
  }
}

.blueButton {
  color: var(--color_primary);
  font-size: var(--fontSize_frameNormal);
}

.toolsContainer {
  display: flex;
  gap: 20px;
}

.toolsItem {
  display: flex;
  gap: 10px;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  color: var(--color_primary);
}

.toolItemRemove {
  color: var(--color_darkRed);
}

.typeContainer {
  display: flex;
  gap: 10px;
}

.textareaTitle {
  font-size: 10px;
  color: var(--color_gray);
}