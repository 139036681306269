.container {
  position: relative;   
}
.wrapper {
  position: relative;
  padding: 19px 0;
  margin-top: 12px;
  border-top: 1px solid var(--color_lightGray);
}

.customTextarea {
  margin-top: -2px !important;
  min-height: 29px !important;
}
/* .customTextarea:empty,
.customTextarea[style*="height: 29px"] {
  height: 29px !;
} */

.createVars {
  position: relative;
  display: flex;
  height: 100%;
  align-items: baseline;
  padding-right: 30px;
  width: fit-content;
  &_title {
    margin-right: 10px;
    font-size: 12px;
    color: var(--color_gray);
    margin-bottom: 10px;
  }
  &_btns {
    display: flex;
    & div {
       cursor: pointer;
       margin-right: 10px;
       & > span {
         margin-right: 10px;
       }
    }
    & .divider {
      width: 1px;
      height: 19px;
      margin: 0 40px 0 30px;
      cursor: initial;
      display: inline-block;
      position: relative;
      &::before {
        content: '';
        width: 1px;
        height: 50px;
        position: absolute;
        background-color: var(--color_lightGray);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &_btn {
    font-size: var(--fontSize_frameNormal);
    padding: 2px 10px;
    line-height: 15px;
    display: inline-block;
    background: var(--color_white);
    color: var(--color_orange);
    border: 1px solid var(--color_orange);
    transition: opacity .2s;
    border-radius: 5px;
    position: relative;
    user-select: none;
    display: flex;
    align-items: center;
    min-height: 19px;
    &[data-faded="true"] {
      opacity: 0.7;
      cursor: default;
    }
    &_add {
      position: relative;
      height: 20px;
      font-size: 12px;
      padding-left: 10px;
    }
    & > span {
      display: inline-block;      
      font-weight: bold;
      &[data-type="dropdown"], &[data-type="date"] {
        width: 10px
      }
      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 100%;
        left: -20px;
        z-index: 12;
      }
    }
    &[data-point="true"] {
      cursor: pointer;  
    }
    & span[data-type="date"]::before {
       content: '';
       position: absolute;
       top: 3px;
       left: 7px;
       background-image: url(/images/button-date.png);
       background-size: 15px;
       background-repeat: no-repeat;
    }
    & span[data-type="dropdown"]::before,
    & span[data-type="dropdown"]::after {
       content: '';
       border-radius: 1px;
       position: absolute;
       background-color: white;
       top: 7px;
       left: 18px;
       width: 1px;
       height: 8px;
       transform: rotate(50deg);
    }
    & span[data-type="dropdown"]::after {
       left: 12px;
       transform: rotate(-50deg);
    }
    /* &[data-point="true"]::before {
       left: -12px;
       background-color: #979797;
    }
    &[data-point="true"]::after {
       left: -18px;
       background-color: #979797;
       transform: rotate(-50deg);
    } */
  }
}

.varContainerExtAdapter {
  width: calc(80rem + 490px);
  height: 0;
  margin: auto;
  position: relative;
}

.varContainerExt {
    position: absolute;
    width: 440px;
    left: calc(80rem + 20px);
    background-color: white;
    z-index: 0;
    top: 100%;/*
    overflow-y: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
       display: none;
       width: 0;
    } */

    &.template {
      margin-top: 74px;
    }

    & > .toggles {
      margin-bottom: 13px;
    }
  }
  .varContainer {
    padding: 7px 0 50px 20px;
    
  }
  .head {
    display: flex;
    align-items: baseline;
    margin-top: 1px;
    margin-bottom: 40px;
    &_title {
      font-size: 12px;
      color: var(--color_gray);
      margin-right: 10px;
    }
    &_radio {
      display: flex;
      > div {
        font-size: 12px;
        line-height: 12px;
        color: var(--color_darkGray);
        display: inline-block;
        padding: 2px 10px 1px;
        border: 1px solid var(--color_lightGray2);
        transition: all .2s;
        cursor: pointer;
        user-select: none;
        &[data-current="true"] {
          color: #fff;
          background-color: var(--color_gray);
          border-color: var(--color_gray);
        }
        &:hover:not([data-current="true"]) {
          color: var(--color_primary_hover);
        }
      }
      > div:not(:first-child):not(:last-child) {
        border-left: none;
        border-right: none;
      }
      > div:first-child {
        border-radius: 5px 0 0  5px;

      }
      > div:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }

.valueDropdown {
  font-size: 14px;
  color: var(--color_darkGray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
.variableField {
  width: 360px;
  position: relative;
  height: fit-content;
  border-radius: 8px;
  border: var(--dashed_border);
  background-color: white;
  margin-bottom: 10px;
  /* box-shadow: 0 1px 14px 0 rgb(0 0 0 / 11%); */
  transition: margin-left .2s;
  transition: background-color .2s;
  &[data-is-valid="true"] {
    border-color: var(--color_lightRed) !important;
    box-shadow: var(--shadow_alert) !important;
  }
  &[data-edited="false"] {
    box-shadow: var(--shadow_alert);
    border-color: var(--color_orange);
  }
  &[data-hi="true"], &[data-pos="true"] {
    background-color: var(--color_yellow_light);
  }
  &[data-filled="true"]:not([data-edited="true"]) {
    width: 320px;  
    margin-left: 40px;
    box-shadow:none;
    & .questionDiv {
      font-size: 12px;
      color: var(--color_gray);
      margin-bottom: 10px; 
    }
    & .valueDiv {
      font-size: 14px;
      color: var(--color_darkGray);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  button {
    font-family: 'ProximaNova', sans-serif !important;
    cursor: pointer;
  }
  &:nth-child(2) .variableField_footer_move :first-child, 
  &:last-child .variableField_footer_move :last-child {
    cursor: default;
    color: var(--color_gray);
  }  
  .questionDiv, .question {
    position: relative;
    word-break: break-word;
    font-size: 14px;
    color: var(--color_darkGray);
    line-height: 17px;
    &[data-req="true"] {
      font-weight: bold;
    }    
  }
  &[data-filled='false'] .questionDiv::before {
    content: '';
    width: 2px;
    height: 65%;
    top: 50%;
    transform: translateY(-50%);
    background: var(--color_orange);
    position: absolute;
    left: -20px;
  }
  .questionDrop {
    font-size: 14px;
    line-height: 17px;
    color: var(--color_darkGray);
    padding-left: 38px;
    position: relative;
    cursor: pointer;
    min-height: 16px;
    &:hover {
       color: var(--color_primary_hover);
    }
    /* &[data-selected="true"] {
      font-weight: bold;
      color: var(--color_darkGray);
      &:hover {
         color: var(--color_primary_hover);
      }
    } */
  }
  .question {
    height: 21px;
    overflow: hidden;
    &::placeholder {
      color: var(--color_orange);
    }
    &[data-used="false"] {
      color: var(--color_darkGray);
      position: relative;
    }
    &[data-used="false"]::before {
      content: '';
      width: 2px;
      height: 65%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      background: undefined;
      background: var(--color_orange);
      position: absolute;
      left: -20px;
    }
  }
  .hint, .hintDiv, .hintDrop {
    /* margin-top: 5px; */
    line-height: 15px;
    font-size: 12px;
    color: var(--color_darkGray);
    position: relative;
  }
  .hintDrop {
    margin: 10px 0 0 40px;
  }
  .hint {
    height: 18px;
    font-size: 12px;
    line-height: 15px;
    overflow: hidden;
    /* text-decoration-color: rgb(21, 126, 251, 0.2);
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-offset: 20%; */

    /* &:hover {
      text-decoration-color: rgb(21, 126, 251);
    }
    */

    &::placeholder {
      /* font-size: 12px !important; */
      color: #157efb !important;
      /* text-decoration-color: rgba(21, 125, 251, 0) !important;
      text-decoration-line: none !important;
      text-decoration-style: none !important; */
    } 

    &[data-is-empty="true"] {
      color: var(--color_primary);
      text-decoration: none;
    }
  }
  .oldinput {
    width: 100%;
    padding: 5px 10px;
    font-size: var(--fontSize_medium);
    border: 1px solid var(--color_lightGray);
    color: var(--color_darkGray);
    border-radius: var(--btn_borderRadius_primary);
    min-height: 60px;
    line-height: 17px;
    scrollbar-width: none;
    overflow: hidden;
  }
  input, textarea {
    font-family: 'ProximaNova', sans-serif !important;
    width: 270px;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    padding: 0;
    margin: 0;
    resize: none;
    &.correctWidth {
      /* width: 190px; */
      text-decoration-color: rgb(21, 126, 251, 0.2);
      text-decoration-style: dashed;
      text-decoration-line: underline;
      text-underline-offset: 20%;
      text-decoration-thickness: 1px;
      &:hover {
        text-decoration-color: rgb(21, 126, 251);
      }
    }
    &.down10 {
      margin-top: 10px;
    }
    /* &.top8 {
      margin-top: -8px;
    } */
  }
  &_docHead {
      padding: 20px;    
  }
  &_head {
    display: flex;
    padding: 20px;
    justify-content: space-between;

    gap: 20px;
    &_inputs {
      /* margin-right: 20px; */
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 5px;

      & .questionContainer {
        position: relative;
        display: flex;
      }


      &:not([data-used="true"]) span::before {
        content: '';
        width: 2px;
        height: calc(100% - 4px);
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background: undefined;
        background: var(--color_orange);
        position: absolute;
        left: -21px;   
      }
    }
    button {
      font-size: 12px;
      color: var(--color_primary);
      border: none;
      background-color: rgba(255, 255, 255, 0);
      width: fit-content;
      padding: 0;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    &_right {
      height: fit-content;
      display: flex;     
      padding-top: 2px;
      &_clpsBtns {
        display: flex;
        align-items: baseline;
      }

      & > .reqCheckbox {
        margin-right: 0;
      }
    }
  }  
  &_dropdownPart {
    padding: 0 20px 20px;
    &_btn {
      background-color: #FFFFFF;
      font-size: 14px;
      padding: 10px 20px;
      border: 1px solid var(--color_primary);
      color: var(--color_primary);
      border-radius: 5px;
      transition: all .2s;
      margin-top: 20px;
      display: flex;
      gap: 10px;
      &:hover {
        opacity: 0.7;
        /* color: var(--color_orange_hover);
        border-color: var(--color_orange_hover); */
      }
    }
    &_options {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &_option {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      /* border: 1px dashed var(--color_orange); */
      /* padding: 20px 20px 10px 20px; */
      /* box-shadow: var(--shadow_alert); */
      
      &[data-draft-doc='false'] {
        border: 1px dashed var(--color_orange);
        border-radius: 8px;
      /* padding: 20px 20px 10px 20px; */
        box-shadow: var(--shadow_alert);
      }
      /* &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:first-child:last-child {
        border-radius: 8px;
      } */
    }
  }
  &_advanced {
    padding: 0 20px 16px;
    input {
      width: 100%;
      padding: 8px 15px;
      font-size: 14px;
      border: 1px solid var(--color_lightGray);
    }
    &_title {
      font-size: 16px;
      font-weight: bold;
      color: var(--color_black);
    }
    &_paramName {
      font-size: 10px;
      color: var(--color_gray);
      margin: 20px 0 0 0 !important;
      /* margin-bottom: 10px; */
    }
    button {
      font-size: 12px;
      color: var(--color_primary);
      margin-right: 10px;
      border: none;
      background-color: rgba(255, 255, 255, 0);
      width: fit-content;
      padding: 0;
    }
    > div {
      margin-bottom: 10px;
    }
    > .lastAdvanced {
      margin-bottom: 0;
      display: flex;
      & > div:nth-child(1) {
        flex-basis: 200px;
      }
      > div:nth-child(2) {
        flex-basis: 158px;
        padding-left: 40px;
      }
    }
    .oldinputNameVar {
      padding: 0;
      border: none;
      min-height: 0;
      text-decoration-color: rgb(21, 126, 251, 0.2);
      /* -webkit-text-decoration-style: dashed; */
      text-decoration-style: dashed;
      /* -webkit-text-decoration-line: underline; */
      text-decoration-line: underline;
      text-underline-offset: 20%;
      border-radius: 0;

      &:hover {
        text-decoration-color: rgb(21, 126, 251);
      }
    }
  }
  &_footer {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    border-top: 1px dashed var(--color_orange);
    &_move {
      display: inline-flex;
      flex-direction: column;
    }
    & > :last-child {
       margin-left: auto;
    }
    button {
      font-size: 12px;
      color: var(--color_primary);
      border: none;
      background-color: rgba(255, 255, 255, 0);
      width: fit-content;
      padding: 0;
      margin-right: 20px;
    }
    &_advancedPart {
      display: inline-block;
      > span {
        font-size: 12px;
        display: inline-block;
        color: var(--color_gray);
        margin-right: 20px;
      }
      & > :last-child {
       margin-right: 0;
      }
    }    
  }

  &_titleBlock {
    input {
      transition: all .2s;
      &._err {
        border-color: var(--color_lightRed);
      }
    }
    .variableField_title {
      font-size: 10px;
      color: var(--color_gray);
      margin-bottom: 10px;
    }
  }
  &_chgInputType {
    margin-top: 10px;
    margin-bottom: 25px;
    & > button {
      border: 1px solid var(--color_orange);
      padding: 2px 10px 1px;
      background-color: #fff;
      color: var(--color_orange);
      font-size: 12px;
      line-height: 12px;
      border-radius: 5px;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &[data-selected] {
        background-color: var(--color_orange);
        color: #fff;
      }
    }
  }
  &_params {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    > button {
      font-size: 12px;
      color: var(--color_primary);
      margin-right: 10px;
      border: none;
      background-color: rgba(255, 255, 255, 0);
      width: fit-content;
      padding: 0;
    }
  }
  &_paramsFields {
    > div {
      margin-bottom: 20px;
      > div:first-child {
        font-size: 10px;
        color: var(--color_gray);
        margin-bottom: 10px;
      }
    }
  }



  &_insertBtn {
    font-weight: bold !important;
    color: var(--color_orange) !important;
    user-select: none;
    font-size: 12px;
    border: none;
    background-color: white;
    width: fit-content;
    padding: 0;
    &[data-off="true"] {
      opacity: 0.3;
      cursor: default;
    }
  }
  &_saveBtn {
    color: var(--color_primary) !important;
    user-select: none;
  }
  &_delBtn {
    color: var(--color_darkRed) !important;
    user-select: none;
  }
  &_disabledBtn {
    color: var(--color_gray) !important;
    cursor: default;
    user-select: none;
  }

  &._collapse {
    .variableField_head_right_reqCheckbox,
    .variableField_advanced,
    .variableField_footer,
    .variableField_dropdownPart {
      display: none;
    }
    .variableField_head {
      border-bottom: none;
    }
  }
}

.chk, .chkBig {
  width: 12px;
  height: 12px;
  transform: translateY(3px);
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: all .2s;
  margin-right: 10px;
  display: inline-block;
  svg {
    width: 8px;
    top: 0;
    left: 1px;
    height: 14px;
    fill: #fff;
    transform: translateY(-2px);
    transition: opacity .2s;
    position: relative;
  }
  &[data-fill="true"] {
    border-color: var(--color_primary);
    background-color: var(--color_primary);
  }
}
.chkBig {
  width: 18px;
  height: 18px; 
  svg {
    width: 14px;
    top: 3px;
    left: 2px;
  }
}
.reqCheckbox, .reqCheckboxBig {
    display: inline-flex;
    white-space: nowrap;
    align-items: baseline;
    font-size: 12px;
    color: var(--color_darkGray);
    cursor: pointer;
    user-select: none;
    margin-right: 12px;
    &[data-read="true"] {
       cursor: initial;
    }
}
.reqCheckboxBig {
    display: block;
    position:absolute;
    left: 0;
    top: -5px;
}
.chk {
  width: 12px;
  height: 12px;
  transform: translateY(4px);
  border: 1px solid #ddd;
  border-radius: 2px;
  transition: all .2s;
  margin-right: 10px;
  display: inline-block;
  svg {
    width: 8px;
    top: 0;
    left: 1px;
    height: 14px;
    fill: #fff;
    transform: translateY(-2px);
    transition: opacity .2s;
    position: relative;
  }
  &[data-fill="true"] {
    border-color: var(--color_primary);
    background-color: var(--color_primary);
  }
  &[data-read="true"] {
    cursor: initial;
  }
}

.typeSelector {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 20px;
  left: -40px;
  top: -20px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 11%);
  z-index: 7;
  transition: all .2s;
  :not(:last-child) {
    margin-bottom: 10px;
  }    
  :last-child {
    margin: 0;
  }
  & > .createVars_btn {
    display: flex;
    gap: 10px;
    width: fit-content;
  }
}

[data-arrow="true"]::before {
   transform: rotate(-50deg) !important;
}
[data-arrow="true"]::after {
   transform: rotate(50deg) !important;
}

.screen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
}
.borderel {
    width: 20px;
    display: inline-block;
}

.cross {
  position:relative;
  cursor: pointer;
  top: 2px;
  &::before,&::after {
    content: '';
    position: absolute;
    background-color: var(--color_lightGray2);
    top: -6px;
    left: 0px;
    width: 1px;
    height: 10px;
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }   
  span {
    position: absolute;
    height: 8px;
    width: 10px;
    left: -3px;
    top: -4px;
    display: inline-block;
    color: white;
    & > div {
       opacity: 0;
       top: 20px;
       right: -12px;
       transition: opacity .1s;
    }
    &:hover > div {
       opacity: 1;
    }
  }
}
.saveBlock {
  display: flex;
  position: absolute;
  bottom: 0px;
  right: 0px;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  align-items: center;
  user-select:none;
  & .cross {
    margin-left: 10px;
    margin-right: 5px;
    top: 0px;
  }
  & > div {
      font-size: 12px;
      color: var(--color_primary);
      &:hover {
         color: var(--color_primary_hover); 
      }
  }
}

[data-select="true"] [data-select] {
    cursor: pointer;
    opacity: 1;
}

.scrollContent {
   padding: 7px 40px 7px 0;
}

.toggles {
  background-color: white;
  border-bottom: 1px solid var(--color_lightGray);
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
  display: inline-block;
  height: 24px;
  margin: 38px 20px 18px;
  z-index: 2;
  .toggle {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
      cursor: pointer;
      transition: all .2s;
      user-select: none;
      &:not(:last-child) {
          margin-right: 20px;
      }
      &:hover {
          color: var(--color_primary_hover);
      }
      &._new {
          color: var(--color_primary);
          font-weight: bold;
      }
      &._unsel {
        color: var(--color_lightGray);
        cursor: initial;
        &:hover {
          color: var(--color_lightGray);
        }
      }
      &._sel {
          color: var(--color_darkGray);
          &::after {
              display: block;
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: -10px;
              left: 0;
              border-radius: 2px 2px 0 0;
              background-color: var(--color_lightGray2);
          }
      }
  }
}

.cond-var {
  color: var(--color_orange);
  position: relative;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  text-indent: 0;
  text-align: left;
  text-transform: initial;
  border: none;
  background: white;
  position: relative;
  padding: 0 10px;
  user-select: none;
  border: dashed 1px var(--color_orange_semi);
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 15%);
  border-radius: 5px 0 0 5px;
  border-left-width: 5px;
  border-left-style: solid;
  cursor: pointer;
  & + .cross {
    top: 9px;
    left: 10px;
  }
}

.cond-question {
  min-width: 40px;
  font-weight: bold;
  position: relative;
}
.cond-option {
  min-width: 30px;
  font-weight: normal;
  border-radius: 0;
  border-left: 1px dashed;
  margin-left: 10px;
  padding-left: 10px;
}
.cond-dropdown {
  position: absolute;
  top: -21px;
  left: -21px;
  opacity: 1;
  cursor: pointer;
  z-index: 2;
  width: 390px;
  display: flex;
  flex-direction: column;
  text-indent: 0;
  padding: 20px 20px 20px 10px;
  background: white;
  border: 1px white solid;
  border-radius: 4px;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 11%);
}
.cond-dropdown-title {
  padding: 0 0 5px 15px;
  font-weight: bold;
  position: relative;
  color: var(--color_orange);
}
.cond-dropdown-option {
  color: var(--color_darkGray);
  margin: 0;
  padding: 0 0 0 15px;
  &:not(:last-child) {
    padding-bottom: 5px;
  }
}
.option_selected {
  font-weight: bold
}
.cond-arrow {
  margin-right: 8px;
  top: 3px;
}
.valueDate{
    font-size: var(--fontSize_readable); 
    color: var(--color_darkGray);
    position: relative;
    cursor: pointer;
}

.optionsArrow {
  aspect-ratio: 10 / 6;
  width: 10px;
  margin-right: 10px;
}

.advancedButton {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer_typeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

textarea.question.correctWidth.correctPlaceholder::placeholder {
  color: #ACACAC;
  text-decoration-color: rgb(21, 126, 251, 0.2);
  text-decoration-style: dashed;
  text-decoration-line: underline;
  text-underline-offset: 20%;
}

textarea.question.correctClause {
  transition: text-decoration-color 0.3s ease;
  color: var(--color_darkGray);

  &.bold {
    font-weight: bold;
  }

  &.normal {
    font-weight: normal;
  }

  &.fs14 {
    font-size: 14px;
  }

  &.fs12 {
    font-size: 12px;
  }

  &.clause {
    &[data-is-empty='true'] {
      text-decoration-color: rgb(21, 126, 251, 0.2);
      text-decoration-style: dashed;
      text-decoration-line: underline;
      text-underline-offset: 20%;
      text-decoration-thickness: 1px;
      color: var(--color_gray);
      
      &:hover, &:focus {
        text-decoration-color: rgb(21, 126, 251, 1);
      }
    }

  }

  &.hint {
    text-decoration-color: rgb(21, 126, 251, 0.2);
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-offset: 20%;
    text-decoration-thickness: 1px;

    &:hover, &:focus {
      text-decoration-color: rgb(21, 126, 251, 1);
    }

    &[data-is-empty='true'] {
      text-decoration: none;
    }
  }

  &.correctPlaceholder {
    text-decoration-color: rgb(21, 126, 251, 0.2);
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-offset: 20%;
    text-decoration-thickness: 1px;

    &::placeholder {
      color: var(--color_gray);
    }

    &:hover, &:focus {
      text-decoration-color: rgb(21, 126, 251, 1);
    }
  }
}

.advancedTools {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  color: var(--color_primary);
  font-size: 12px;
  margin-bottom: 0 !important;
  margin-top: 20px;
  line-height: 15px;

  & .toolsItem {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 0;

    & img {
      max-height: 10px;
      max-width: 10px;
    }
  }

  & .toolItemRemove {
    color: var(--color_darkRed);
  }
}

.variableField_dropdownPart_option {
  .variableField_dropdownPart_option_content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .variableField_dropdownPart_option_left {
    /* textarea {
      width: 100%;

      &::placeholder {
        width: 140px;
        line-height: 17px;
        font-size: 14px;
        color: var(--color_gray);
      }
    } */

    & textarea.title {
      text-decoration-color: rgb(21, 126, 251, 0.2);
      text-decoration-style: dashed;
      text-decoration-line: underline;
      text-underline-offset: 20%;
      color: var(--color_darkGray);
      max-width: 140px;
      border: none;
      border-radius: 0;

      &:hover {
        text-decoration-color: rgb(21, 126, 251);
      }

      &::placeholder {
        color: var(--color_gray);
      }
    }
    
    & textarea.hint {
      &[data-is-empty='true']  {
        color: var(--color_primary);
        &::placeholder {
          color: var(--color_primary);
        }
      }

      &[data-is-empty='false'] {
        text-decoration-color: rgb(21, 126, 251, 0.2);
        text-decoration-style: dashed;
        text-decoration-line: underline;
        text-underline-offset: 20%;
        color: var(--color_darkGray);

        &:hover {
          text-decoration-color: rgb(21, 126, 251);
        }
      }
      
      margin-top: 0px;
      max-width: 140px;
      border: none;
      border-radius: 0;
    }

    max-width: 140px;
  }
  
  .variableField_dropdownPart_option_right {
      /* padding-left: 20px; */
      width: min-content;
  }

  .variableField_dropdownPart_option_footer {
      width: 100%;
      padding: 10px 20px;
      border-top: dashed 1px #f8964082;
      display: flex;
      gap: 20px;

      button {
        background: none;
        outline: none;
        border: none;
      }
  }  
}

/* .variableField_dropdownPart_option .variableField_dropdownPart_option_left textarea.hint::placeholder {
  text-decoration-line: none !important;
} */

.correctHeight {
  min-height: 28px !important;
  line-height: 16px !important;
}

textarea.correctWidth.correctPlaceholder {
  font-size: 14px;
}

.checkboxSize12 {
  width: 12px;

  & > span {
    height: 12px;
    width: 12px;

    & > svg {
      width: calc(12px * 7 / 9);
      height: calc(12px* 7 / 9);
      /* top: 50%; */
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.mtm4 {
  margin-top: -4px;
}

.checkboxSize18 {
  width: 18px;

  & > span {
    height: 18px;
    width: 18px;

    & > svg {
      width: calc(18px * 7 / 9);
      height: calc(18px* 7 / 9);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.checkboxGreenFilled {
  & > span[data-fill='true'] {
    background: var(--color_green);
    border-color: var(--color_green);
  }
}

.relative {
  position: relative;
}

.resetPosition {
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
}

.flex {
  display: flex;
}

.gap8 {
  gap: 8px;
}

.resetMargin {
  margin: 0;

  & * {
    margin: 0;
  }
}

.minWidth100Percent {
  & > span {
    min-width: 100%;
  }
}

.translateY1 {
  & > span {
    transform: translateY(1px);
  }
}

.toggles {
  background-color: white;
  border-bottom: 1px solid var(--color_lightGray);
  font-size: var(--fontSize_standart);
  color: var(--color_gray);
  display: inline-block;
  height: 24px;
  margin-bottom: 18px;
  z-index: 2;
  .toggle {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
      cursor: pointer;
      transition: all .2s;
      user-select: none;
      &:not(:last-child) {
          margin-right: 20px;
      }
      &:hover {
          color: var(--color_primary_hover);
      }
      &._new {
          color: var(--color_primary);
          font-weight: bold;
      }
      &._sel {
          color: var(--color_darkGray);
          &::after {
              display: block;
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              bottom: -10px;
              left: 0;
              border-radius: 2px 2px 0 0;
              background-color: var(--color_lightGray2);
          }
      }
  }
}
/* For comments */

.commentsAnimationContainer {
  opacity: 0;
  transition: 0.2s ease-in;
  position: absolute;
  z-index: 11;
  left: 0px;
  height: 10px;
  width: 500px;
  overflow: hidden;
}

.commentsTypeContainer {
  transform: translateY(-59px);
  transition: 0.2s ease-in;
  width: 400px;
  height: 59px;
  padding: 20px 0px;
  margin-left: 20px;
  display: flex;
  background-color: var(--color_white);;
  & :first-child {
      border-radius: 5px 0 0 5px;
      border-right: 0px;
  }
  & :last-child {
      border-radius: 0 5px 5px 0;
      border-left: 0px;
  }
}

.commentsType {
  padding: 2px 10px;
  border: solid 1px var(--color_lightGray2);
  &.active {
      border: solid 1px var(--color_gray);
      background-color: var(--color_gray);
      color: var(--color_white);
  }
  &:hover {
      cursor: pointer;
  }
}

.commentsAnimationContainer:hover, .toggles:hover .commentsAnimationContainer {
  opacity: 1;
  transition: 0.2s ease-in;
  transition-delay: 0.5s;
  height: 60px;
}

.commentsAnimationContainer:hover .commentsTypeContainer, .toggles:hover .commentsTypeContainer {
  transform: translateY(0px);
  transition: 0.2s ease-in;
  transition-delay: 0.5s;
}

.commentsTypeContainer:hover, .toggles:hover .commentsTypeContainer {
  transform: translateY(0px);
  transition: 0.2s ease-in;
  transition-delay: 0.5s;
}


