.sidebar-export-container {
    margin-left: 20px;
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        position: absolute;
        left: -20px;
        top: 0px;
        content: url('/images/icons-12-download.svg');
    }

    &> .arrow-container {
        margin-left: 10px;
        height: 12px;
        width: 20px;
        cursor: pointer;
    }
}

.sidebar-export-params {
    &.info {
        position: relative;
        color: var(--color_lightRed);
        font-size: var(--fontSize_standart);
        margin-left: 20px;
        margin-bottom: 12px;
        &::before {
            position: absolute;
            left: -20px;
            top: 0px;
            content: url('/images/icons-12-warning.svg');
        }
    }
}
