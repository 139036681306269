.container {
  display: flex;
  gap: 8px;
  width: 360px;
  margin: 0 40px 0 0;
  padding: 10px 20px;
  border-radius: var(--btn_borderRadius_primary);
  box-shadow: var(--shadow_menu);
  background-color: var(--color_white);
  color: var(--color_lightRed);
  margin-bottom: 8px;
}

.container p {
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 15px;
}