.wrapper {
    position: relative;
    font-family: var(--fontFamily);
    color: var(--color_black);
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: border .2s, color .2s, background-color .2s;
    user-select: none;
    width: fit-content;
}

/*  public area  */
.promoMainBtn, .signIn, .promoChgd {
    font-size: var(--fontSize_tab);
    width: 150px;
    height: 40px;
    border-radius: var(--btn_borderRadius_big);
    text-align: center;
    color: #fff;
    background-color: var(--color_primary);
    &:hover {
        background-color: var(--color_primary_hover__dark);
    }
}
.signIn {
    width: 90px;
 }
 .promoChgd {
    color: var(--color_darkGray);
    border: 1px solid var(--color_lightGray2);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: transparent;
    &:hover {
        transition-delay: 9999999s;
    }
  }

.freePad {
    padding: 0 20px;
    width: fit-content;
}
/* -- */
.top10 {
    margin-top: 10px;
}
.blue, .green, .red, .orange, .gray, .black {
    font-size: var(--fontSize_button);
    margin-right: 20px;
    line-height: 1.3;
}
.blue {
    color: var(--color_primary);
    border-color: var(--color_primary);
    &:hover {
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}
.bluewhite {
    color: var(--color_primary);
    border-color: white;
    &:hover {
        color: var(--color_primary_hover);
        border-color: white;
    }
}
.green {
    color: var(--color_green);
    border-color: var(--color_green);
    &:hover {
        color: var(--color_green_hover);
        border-color: var(--color_green_hover);
    }
}
.red {
    color: var(--color_darkRed);
    border-color: var(--color_darkRed);
    &:hover {
        color: var(--color_darkRed_hover);
        border-color: var(--color_darkRed_hover);
    }
}
.orange {
    color: var(--color_orange);
    border-color: var(--color_orange);
    &:hover {
        color: var(--color_orange_hover);
        border-color: var(--color_orange_hover);
    }
}
.gray {
    color: var(--color_gray);
    border-color: var(--color_gray);
    &:hover {
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}
.black {
    color: var(--color_black);
    border-color: var(--color_black);
    &:hover {
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}
.black {
    color: var(--color_black);
    border-color: var(--color_black);
    &:hover {
        color: var(--color_primary_hover);
        border-color: var(--color_primary_hover);
    }
}

.fill {
    color: white;
    padding: 2px 10px 0px;
    line-height: 1.3;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--btn_borderRadius_primary);
    &:hover {
        color: white;
    }
    &.blue {
        background-color: var(--color_primary);
        &:hover {
            background-color: var(--color_primary_hover);
        }
    }
    &.green {
        background-color: var(--color_green);
        &:hover {
            background-color: var(--color_green_hover);
        }
    }
    &.red {
        background-color: var(--color_darkRed);
        &:hover {
            background-color: var(--color_darkRed_hover);
        }
    }
    &.orange {
        background-color: var(--color_orange);
        &:hover {
            background-color: var(--color_orange_hover);
        }
    }
    &.gray {
        background-color: var(--color_gray);
        &:hover {
            background-color: var(--color_gray);
        }
    }
}
.arch {
    background-color: var(--color_white);
    border: 1px solid;
    padding: 2px 10px 0px;
    line-height: 1.3;
    border-radius: var(--btn_borderRadius_primary);
    &.blue {
        color: var(--color_primary);
        &:hover {
            color: var(--color_primary_hover);
        }
    }
    &.green {
        color: var(--color_green);
        &:hover {
            color: var(--color_green_hover);
        }
    }
    &.red {
        color: var(--color_darkRed);
        &:hover {
            color: var(--color_darkRed_hover);
        }
    }
    &.orange {
        color: var(--color_orange);
        &:hover {
            color: var(--color_orange_hover);
        }
    }
    &.gray {
        color: var(--color_gray);
        &:hover {
            color: var(--color_primary_hover);
        }
    }
}

.approve, .approveAgain, .approved {
    color: var(--color_primary);
    font-size: var(--fontSize_button);
    text-align: left;
    height: 21px;
    padding: 0 0 0 30px;
    margin: 0;
    background-image: url(/images/approvalTrue.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
}
.approveAgain {
    white-space: normal;
    height: 30px;
    background-image: url(/images/approvalTrue.svg);
    background-size: 21px 21px;
    background-position: 0 6px;
}
.approved {
    background-image: url(/images/approved.svg);
    background-position: 0 0;
}

.shared {
   color: var(--color_darkRed);
   padding-left: 0px;
   white-space: nowrap; 
    font-size: var(--fontSize_button);
    text-align: left;
    z-index: 2;
    padding-left: 30px;
    padding-right: 26px;
    margin-right: -5px;
    height: 21px;
    border: none;
}
.send:not(.big) {
    color: var(--color_primary);
    font-size: var(--fontSize_button);
    text-align: left;
    z-index: 2;
    padding-left: 30px;
    padding-right: 26px;
    margin-right: -5px;
    height: 21px;
    border: none;
    background: no-repeat 0 0px/19px 19px url(/images/send.svg);
    transition: all 0s .2s;
    &::before, &::after {
        content: '';
        border-radius: 1px;
        position: absolute;
        background-color: #979797;
        top: 6px;
        right: 11px;
        width: 1px;
        height: 8px;
        transform: rotate(-50deg);
    }
    &::after {
        right: 5px;
        transform: rotate(50deg);
    }
}
.send.send-open {
    transition: all 0s 0s;
    z-index: 31;
    &::before {
        transform: rotate(50deg);
    }
    &::after {
        transform: rotate(-50deg);
    }
}

.send.big {
    text-align: left;
    padding-left: 45px;
    background-image: url(/images/icon-envelope-white.png);
    background-repeat: no-repeat;
    background-size: 19px 18px;
    background-position: 89px 50%;
    &.rus {
        background-position: 112px 50%;
        padding-left: 25px;
    }
}

.leftArrow {
    position: relative;
    padding-right: 24px;
    &::before, &::after {
        content: '';
        border-radius: 1px;
        position: absolute;
        background-color: #979797;
        top: 3px;
        right: 10px;
        width: 1px;
        height: 8px;
        transform: rotate(-50deg);
    }
    &::after {
        right: 4px;
        transform: rotate(50deg);
    }
    &_open {
        &::before, &::after {
            transform: rotate(50deg);
        }
        &::after {
            transform: rotate(-50deg);
        }
    }
}

.changes {
    z-index: 2;
    transition: all 0s .2s;
    color: var(--color_primary);
    font-size: var(--fontSize_button);
    &_open {
        transition: all 0s 0s;
        z-index: 6;
    }
}

.hint {
    position: absolute;
    width: 124px;
    left: -62px;
    bottom: calc(100% + 8px);
    border-radius: var(--btn_borderRadius_big);
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--color_darkGray);
    font-size: var(--fontSize_tip);
    font-weight: normal;
    color: var(--color_lightGray2);
    padding: 10px;
    cursor: initial;
}

.wrapper:hover .hint {
    opacity: 1;
}

.hint:before {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 6px);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-top: 8px solid var(--color_darkGray);
    border-right: 6px solid transparent;
}
.toHide {
    position: absolute;
    left: -2px;
    top: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: white;
    opacity: 0.66;        
}
.big {
    font-size: var(--fontSize_tab);
    width: 150px;
    height: auto;
    line-height: 1.15;
    padding: 12px;
    border-radius: var(--btn_borderRadius_big);
}
.small {
    font-size: var(--fontSize_tip);
    line-height: 10px;
    height: auto;
    padding: 2px 6px 0px;
    border-radius: var(--btn_borderRadius_small);
}

.nowrap {
    white-space: nowrap;
}
.bold {
    font-weight: bold;
}
.w220 {
    width: 220px;
}
.margin0 {
    margin-right: 0;
}
.disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
        transition-delay: 999999s;
    }
}

.tgIcon {
    background-image: url(/images/icon-link.svg);
    padding-right: 23px;
    background-repeat: no-repeat;
    background-position: calc(100% - 4px) 8px;
}

.wrapper.aleft {
    text-align: left;
}