.track {
  width: 7px;
  right: 0;
  top: 0;
  height: 100%;
}

.thumb {
  background-color: #cacaca;
  border-radius: 4px;
  cursor: pointer;
}
.invisible {
  opacity: 0
}

.thumb:hover {
  background-color: #bababa;
}

.overflowVisible {
  overflow: visible;
}
