:root {
  /* colors variables */
  --color_primary: #157efb; 
  --color_primary_opacity: rgb(21, 126, 251, 0.2);
  --color_primary_hover: #1DA6E5; 
  --color_primary_hover__dark: #0368e0;
  --color_primary_semi: #b7d6fc;
  --color_black: #212121;
  --color_light_black: #252525;
  --color_darkGray: #606060;
  --color_gray: #ACACAC; --color_gray_hover: rgba(172, 172, 172, 0.75);
  --color_lightGray2: #DEDEDE;
  --color_lightGray3: #F7F7F7;
  --color_lightGray4: #b5b5b5;
  --color_lightGray: #EEEEEE;
  --color_white: #FFF;
  --color_green: #00ab26; 
  --color_green_hover: rgba(0, 171, 38, 0.75);
  --color_green_semi: rgba(0, 171, 38, 0.35);
  --color_green_light: rgba(0, 171, 38, 0.15);
  --color_darkRed: #D0021B; --color_darkRed_hover: rgba(208, 2, 27, 0.75);
  --color_lightRed: #FF6C6C;
  --color_orange: #F89540; 
  --color_orange_hover: #ffb85c;
  --color_orange_semi: rgba(248, 149, 64, 0.502);
  --color_orange_light: rgba(248, 149, 64, 0.15);
  --color_yellow_light: rgba(255, 233, 0, 0.2);
  --color_yellow_semi: rgba(255, 233, 0, 0.5);
  --color_yellow: #ffc003;
  --color_yellow_frame: #ffeb3b38;

  /* font-size variables */
  --fontSize_h1: 35px;
  --fontSize_h2: 21px;
  --fontSize_18: 19px;
  --fontSize_h3: 17px;
  --fontSize_h4: 15px;
  --fontSize_tab: 15px;
  --fontSize_standart: 13px;
  --fontSize_medium: 14px;
  --fontSize_9: 10px;
  --fontSize_tip: 11px;
  --fontSize_button: 13px;
  --fontSize_bigPopup: 24px;
  --fontSize_readable: 15px;

  --fontSize_frameSmall: 10px;
  --fontSize_frameNormal: 12px;

  /* box-shadow variables */
  --shadow_big: -5px 0 80px 0 rgba(0, 0, 0, 0.15);
  --shadow_menu: 0 1px 20px 0 rgba(0, 0, 0, 0.11);
  --shadow_alert: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  --shadow_stylesBlock: 0 1px 5px 0 rgba(0, 0, 0, 0.11);

  /* border variables */
  --dashed_border: 1px dashed var(--color_orange);
  --dashed_border_grey: 1px dashed var(--color_lightGray2);

  /* buttons variables */
  --btn_borderRadius_small: 4px;
  --btn_borderRadius_primary: 5px;
  --btn_borderRadius_big: 6px;
  --btn_padding: 2px 10px;

  /* gradient colors */
  --gradient_black0: rgba(255, 255, 255, 0)
}

.wrapper {
  /*min-width: 1346px;*/
  max-width: 2520px;
  margin: 0 auto;
  min-height: 100vh;
  font-family: var(--fontFamily);
  color: var(--color_black);
  position: relative;
}