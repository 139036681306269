.textarea {
  resize: none;
  border: none;
  outline: none;
  height: auto;
  overflow: hidden;
  line-height: normal;
  color: var(--color_darkGray);
  transition: text-decoration-color 0.3s ease;

  &.underline {
    text-decoration-color: rgb(21, 126, 251, 0.2);
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-offset: 20%;
    text-decoration-thickness: 1px;

    &:hover,
    &:focus {
      text-decoration-color: rgb(21, 126, 251, 1);
    }
  }

  &::placeholder {
    color: var(--color_gray);
  }
}
