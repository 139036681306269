.container {
    display: flex;
    flex-direction: column;
}

.heading {
    font-size: var(--fontSize_standart);
    color: var(--color_gray);
    margin-bottom: 2px;
    max-width: 80px;
}

.svgIcons {
    width: 128px;
    height: 19px;
    margin-right: 20px;
    /* border-radius: 5px; */
    display: flex;  
    & > div {
        text-align: center;
        flex-grow: 1;
        border: solid 1px var(--color_lightGray2);
        border-right: solid 0px;
        
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & :first-child {
        border-radius: 5px 0 0 5px;
    }
    & :last-child {
        border-radius: 0 5px 5px 0;
    }
    & > div:last-child {
        border-right: solid 1px var(--color_lightGray2);
    }
    & > div:has( > svg > .active):last-child {
        border-right: 0px;
    }
    &:hover {
        cursor: pointer;
    }
}

div:has( > svg > .active) {
    background-color: var(--color_gray);
    border: solid 1px var(--color_gray);
    & + div {
        border-left: solid 0px;
    }
}

.svg {
    stroke: var(--color_gray);
    &.active {
        stroke: var(--color_white);
    }
}